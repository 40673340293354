<template>
    <div class="bg-page">
        <van-nav-bar :border="false"
                     :placeholder="true"
                     :fixed="true"
                     :left-text="$t('提款方式')"
                     left-arrow
                     @click-left="onClickLeft"
                     @click-right="onClickRight"
        >
            <template #right>
                <span class="btn btn-black iconfont icon-plus"></span>
            </template>
        </van-nav-bar>
        <div class="wt-wrapper">
            <div class="quk-card">
                <div class="quk-item">
                    <div class="value lg-value">{{user.balance}} <span class="text-gray" style="font-size: 12px">{{$t('USDT')}}</span></div>
                    <div class="label text-gray">{{$t('钱包余额')}}</div>
                </div>
                <div class="quk-item">
                    <div class="value">{{user.huilv_withdraw}}</div>
                    <div class="label text-gray">{{$t('汇率')}}</div>
                </div>
            </div>
            <div class="wt-panel bs-panel">
                <div class="wt-input_items u-m-b15">
                    <div class="hd u-m-b10">{{$t('金额')}} <span class="text-gray" style="font-size: 12px">( {{$t('USDT')}} )</span></div>
                    <van-field v-model="money" type="number" :placeholder="$t('请输入提现金额')" />
                </div>
                <div class="tri-cellss" style="color: #009d64" v-if="radio == 'BANK' && money > 0">
                    {{$t('到账金额')}} : {{parseFloat(money * user.huilv_withdraw).toFixed(2)}}
                    <span style="font-size: 12px"> {{$t('R$')}}</span>
                </div>
                <!-- <div class="tri-cell text-yellow u-m-b20" v-html="$t('提现说明提示')"></div> -->
                <div class="wt-list-cells">
                    <van-radio-group v-model="radio">
                        <van-cell-group>
                            <van-cell v-if="withdraw_info.usdt == '1'" center :title="$t('USDT')"
                                        :label="$t('强烈推荐TRC20，免手续费')" clickable
                                        @click="radio = 'TRC20'">
                                <template #icon>
                                    <img src="../../assets/pictures/t-logo3.png" class="wt-icon" alt="">
                                </template>
                                <template #right-icon>
                                    <van-radio name="TRC20">
                                        <template #icon="props">
                                            <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                                        </template>
                                    </van-radio>
                                </template>
                            </van-cell>
                            <!-- <van-cell v-if="withdraw_info.usdt == '1'" center :title="$t('USDT（ERC20）')"
                                        :label="$t('ERC20 TIPS')"
                                        clickable
                                        @click="radio = 'ERC20'">
                                <template #icon>
                                    <img src="../../assets/pictures/t-logo3.png" class="wt-icon" alt="">
                                </template>
                                <template #right-icon>
                                    <van-radio name="ERC20">
                                        <template #icon="props">
                                            <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                                        </template>
                                    </van-radio>
                                </template>
                            </van-cell> -->
                            <!-- <van-cell center :title="$t('银行卡')" clickable @click="radio = 'BANK'" :label="$t('bank withdraw')">
                                <template #icon>
                                    <img src="../../assets/pictures/bank-ico.png" class="wt-icon" alt="">
                                </template>
                                <template #right-icon>
                                    <van-radio name="BANK">
                                        <template #icon="props">
                                            <img class="img-icon" :src="props.checked ? activeIcon : inactiveIcon"/>
                                        </template>
                                    </van-radio>
                                </template>
                            </van-cell> -->
                        </van-cell-group>
                    </van-radio-group>
                </div>
            </div>
        </div>
        <div class="btn-space" style="padding: 30px">
            <van-button color="linear-gradient(to right, #3270e0, #3653ea)" block @click="handleClickOk">{{$t('确认')}}</van-button>
        </div>

        <!-- 弹窗 -->
        <van-popup class="page-popup" v-model="show2" position="center" closeable
                   :style="{ borderRadius: '10px'}">
            <div class="wt-dialog">
                <div class="wt-top">{{$t('选择取款方式')}}</div>
                <div class="wt-panel wt-list-cells">
                    <van-radio-group v-model="add_radio">
                        <van-cell v-if="withdraw_info.usdt == '1'" center :title="$t('USDT')"
                                  :label="$t('强烈推荐TRC20，免手续费')" clickable
                                  @click="add_radio = 'TRC20'">
                            <template #icon>
                                <img src="../../assets/pictures/t-logo3.png" class="wt-icon" alt="">
                            </template>
                            <template #right-icon>
                                <van-radio name="TRC20">
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? activeCheck : inactiveCheck"/>
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell>
                        <!-- <van-cell v-if="withdraw_info.usdt == '1'" center :title="$t('USDT（ERC20）')"
                                  clickable
                                  @click="add_radio = 'ERC20'">
                            <template #icon>
                                <img src="../../assets/pictures/t-logo3.png" class="wt-icon" alt="">
                            </template>
                            <template #right-icon>
                                <van-radio name="ERC20">
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? activeCheck : inactiveCheck"/>
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell> -->
                        <!-- <van-cell center :title="$t('银行卡')" clickable @click="add_radio = 'BANK'">
                            <template #icon>
                                <img src="../../assets/pictures/bank-ico.png" class="wt-icon" alt="">
                            </template>
                            <template #right-icon>
                                <van-radio name="BANK">
                                    <template #icon="props">
                                        <img class="img-icon" :src="props.checked ? activeCheck : inactiveCheck"/>
                                    </template>
                                </van-radio>
                            </template>
                        </van-cell> -->
                    </van-radio-group>
                    <div class="popbtn">
                        <van-row :gutter="10">
                            <van-col :span='12'><van-button type="orange" class="nfobtn" block @click="show2 = false">{{$t('取消')}}</van-button></van-col>
                            <van-col :span='12'><van-button color="linear-gradient(to right, #3270e0, #3653ea)" block @click="toBind">{{$t('确定')}}</van-button></van-col>
                        </van-row>
                    </div>
                </div>
            </div>
        </van-popup>


        <van-popup  class="bs-pop" v-model="show3" position="bottom">
            <div class="bank-pop">
                <div class="wt-top">{{$t('设置交易密码')}}</div>
                <div class="wt-dialog_bd">
                    <div style="font-size: 14px;padding: 20px">
                        {{$t('您还没有设置交易密码，是否继续操作？')}}
                    </div>
                    <div class="popbtn" style="padding: 0 20px 20px 20px">
                        <van-row :gutter="24">
                            <van-col :span="12">
                                <van-button block @click="handleClickOk2" type="orange">{{$t('继续')}}</van-button>
                            </van-col>
                            <van-col :span="12">
                                <van-button block to="/pay-set" color="linear-gradient(to right, #3270e0, #3653ea)">{{$t('设置')}}</van-button>
                            </van-col>
                        </van-row>
                    </div>
                </div>
            </div>
        </van-popup>

        <van-popup  class="bs-pop" v-model="show4" position="bottom">
            <div class="bank-pop">
                <div class="wt-top">{{$t('交易密码')}}</div>
                <div class="wt-dialog_bd">
                    <van-field v-model="pay_password" class="pay-password" :placeholder="$t('请输入交易密码')" type="password" maxlength="6" />
                    <div class="popbtn" style="padding: 0 20px 20px 20px">
                        <van-row :gutter="24">
                            <van-col :span="12">
                                <van-button block @click="show4 = false" type="orange" >{{$t('取消')}}</van-button>
                            </van-col>
                            <van-col :span="12">
                                <van-button block @click="handleClickOk2" color="linear-gradient(to right, #3270e0, #3653ea)">{{$t('确认')}}</van-button>
                            </van-col>
                        </van-row>
                    </div>
                </div>
            </div>
        </van-popup>
    </div>
</template>

<script>
    export default {
        components: {},
        data() {
            return {
                radio: 'TRC20',
                add_radio: 'TRC20',
                user: {},
                bank_list: [],
                withdraw_info: {},
                show: false,
                show2: false,
                show3: false,
                show4: false,
                pay_password: '',
                bank_id: 0,
                money: '',
                bank_code: '',
                activeIcon: require('../../assets/pictures/w-checked.png'),
                inactiveIcon: require('../../assets/pictures/w-check.png'),
                activeCheck: require('../../assets/pictures/w-checked.png'),
                inactiveCheck: require('../../assets/pictures/w-check.png'),
            }
        },
        methods: {
            onClickLeft() {
                this.$router.go(-1)
            },
            onClickRight() {
                if (this.withdraw_info.usdt) {
                    this.show2 = true;
                } else {
                    this.$router.push({path: '/bank-bind',});
                }
            },
            toBind() {
                if (this.add_radio == 'BANK') {
                    this.$router.push({path: '/bank-bind',});
                } else if (this.add_radio == 'PIX'){
                    this.$router.push({path: '/pix-bind',});
                } else {
                    this.$router.push({path: '/bind-wallet', query: {channel: this.add_radio}});
                }
            },
            getData() {
                this.$axios.get(this.$api_url.get_bind_bank + '?id=-1&tag=list').then((responese) => {
                    let res = responese.data
                    this.bank_list = res.data.info;
                }).catch((err) => {
                    this.$toast.fail(err.message);
                });
            },
            getUser() {
                this.$axios
                    .get(this.$api_url.user + "?type=2")
                    .then((res) => {
                        this.user = res.data;
                    })
            },
            handleClickOk() {
                if (this.bank_list != undefined && this.bank_list.length > 0) {
                    let bool = false;
                    this.bank_list.forEach((item) => {
                        if (item.type == 1 && item.bank_name == this.radio) {
                            bool = true;
                            this.bank_id = item.id;
                        }
                        if (item.type == 0 && this.radio == 'BANK') {
                            bool = true;
                            this.bank_id = item.id;
                        }
                        if (item.type == 2 && this.radio == 'PIX') {
                            bool = true;
                            this.bank_id = item.id;
                        }
                    });
                    if (!bool) {
                        if (this.withdraw_info.usdt) {
                            this.show2 = true;
                        }else{
                            this.show = true;
                        }
                        this.$toast.fail(this.$t('请先绑定提现渠道'));
                        return;
                    }
                } else {
                    if (this.withdraw_info.usdt) {
                        this.show2 = true;
                    }else{
                        this.show = true;
                    }
                    this.$toast.fail(this.$t('请先绑定提现渠道'));
                    return;
                }

                if (this.money <= 0) {
                    this.$toast.fail(this.$t('请输入正确的提现金额'));
                    return;
                }
                if (this.money < parseInt(this.withdraw_info.money)) {
                    this.$toast.fail(this.$t('最低提现金额为：') + this.withdraw_info.money);
                    return;
                }
                if (this.money > parseInt(this.withdraw_info.max_money)) {
                    this.$toast.fail(this.$t('最高提现金额为：') + this.withdraw_info.max_money);
                    return;
                }
                if (!this.bank_id) {
                    this.$toast.fail(this.$t('请选择提款银行卡'));
                    return;
                }

                if(this.withdraw_info.pay_flag == 1){
                    this.pay_password = '';
                    this.show4 = true;
                }else{
                    this.show3 = true;
                }
            },
            handleClickOk2() {
                if (this.bank_list != undefined && this.bank_list.length > 0) {
                    let bool = false;
                    this.bank_list.forEach((item) => {
                        if (item.type == 1 && item.bank_name == this.radio) {
                            bool = true;
                            this.bank_id = item.id;
                        }
                        if (item.type == 0 && this.radio == 'BANK') {
                            bool = true;
                            this.bank_id = item.id;
                        }
                    });
                    if (!bool) {
                        if (this.withdraw_info.usdt) {
                            this.show2 = true;
                        }else{
                            this.show = true;
                        }
                        this.$toast.fail(this.$t('请先绑定提现渠道'));
                        return;
                    }
                } else {
                    if (this.withdraw_info.usdt) {
                        this.show2 = true;
                    }else{
                        this.show = true;
                    }
                    this.$toast.fail(this.$t('请先绑定提现渠道'));
                    return;
                }

                if (this.money <= 0) {
                    this.$toast.fail(this.$t('请输入正确的提现金额'));
                    return;
                }

                if(this.withdraw_info.pay_flag == 1 && this.pay_password == ''){
                    this.$toast.fail(this.$t('请输入交易密码'));
                    return;
                }

                if (this.money < parseInt(this.withdraw_info.money)) {
                    this.$toast.fail(this.$t('最低提现金额为：') + this.withdraw_info.money);
                    return;
                }
                if (this.money > parseInt(this.withdraw_info.max_money)) {
                    this.$toast.fail(this.$t('最高提现金额为：') + this.withdraw_info.max_money);
                    return;
                }
                if (!this.bank_id) {
                    this.$toast.fail(this.$t('请选择提款银行卡'));
                    return;
                }
                if (this.money) {
                    let loading = this.$toast.loading();
                    this.$axios
                        .post(
                            this.$api_url.withdraw_add,
                            this.$qs.stringify({
                                money: this.money,
                                bank_id: this.bank_id,
                                pay_password: this.pay_password
                            })
                        )
                        .then((res) => {
                            if (res.data.code === 0) {
                                this.show4 = false;
                                this.$toast.success(res.data.msg);
                                this.$router.push({path: '/withdraw-record'});
                            } else {
                                this.$toast.fail(res.data.msg);
                            }
                        })
                        .catch((err) => {
                            this.$toast.fail(err.message);
                        });
                }
            },
            getWithdrawInfo() {
                this.$axios
                    .get(this.$api_url.withdraw_info)
                    .then((res) => {
                        this.withdraw_info = res.data;
                    })
            }
        },
        mounted() {
            this.getUser();
            this.getData();
            this.getWithdrawInfo();
        }

    }
</script>
<style scoped>
    .bg-page{
        min-height: 100vh;
        background-color: #1a2331;
    }
    .bg-page ::v-deep .van-nav-bar{
        background-color: #1a2331;
    }
    .wt-dialog_bd ::v-deep .van-cell{
        background-color: transparent;
        margin-bottom: 15px;
    }
    .wt-dialog_bd ::v-deep .van-cell::after{
        display: none;
    }
    .wt-dialog_bd ::v-deep .van-field__control{
        border-radius: 10px;
        height: 40px;
        background-color: #1f1f1f;
        color: #ffffff;
        padding: 0 15px;
    }
    
    .quk-card{
        display: flex;
        align-items: center;
    }
    .icon-plus{
        max-width: 36px;
    }
    .wt-input_items .hd{
        font-size: 16px;
        font-weight: bold;
    }
    .tri-cellss{
        font-size: 14px;
        margin-bottom: 10px;
    }
    .wt-list-cells  ::v-deep .van-cell-group{
        background-color: transparent;
    }
    .wt-input_items ::v-deep .van-field__control{
        color: #ffffff;
    }
    .wt-list-cells  ::v-deep .van-cell-group::after{
        display: none;
    }
    .wt-list-cells  ::v-deep .van-cell{
        background-color: #1a2331;
        border: 1px solid #757a7b;
        border-radius: 16px;
        margin-bottom: 15px;
        color: #ffffff;
    }
    .wt-list-cells  ::v-deep .van-cell::after{
        display: none;
    }
    .wt-input_items ::v-deep .van-cell{
        background-color: #2c384a;
        border-radius: 16px;
    }
    .wt-panel{
        background-color: #11161b;
        padding: 35px 15px 15px 15px;
        border-radius:  0 0 30px 30px;
        margin-top: -20px;
    }
    .wt-icon{
        width: 28px;
        height: 28px;
        margin-right: 10px;
    }
    .img-icon{
        width: 20px;
        height: 20px;
    }
    .quk-card{
        position: relative;
        z-index: 22;
        justify-content: space-between;
        padding: 20px;
        font-size: 14px;
        border-radius: 0 0 30px 30px;
        background-color: #1a2331;
    }
    .lg-value{
        font-size: 20px;
        font-weight: bold;
    }
    .quk-card .label{
        margin-top: 10px;
    }
    .quk-item:last-child{
        text-align: right;
    }
    .bank-pop .wt-top{
        font-size: 18px;
        padding: 15px;
    }
    .icon-plus{
        background-color: #2c384a;
        border-radius: 10px;
    }





</style>
